export default {
  watch: {
    value (value) {
      this.localValue = value?.replace(/,/g, '.')
    }
  },
  data () {
    return {
      localValue: null
    }
  },
  mounted () {
    if (typeof this.value !== 'undefined' && this.value !== null) {
      this.onChange(this.value)
    }
  },
  methods: {
    onChange (value) {
      let clearValue = this.formatString(value)
      let formattedValue = new Intl.NumberFormat('ru-RU', {
        style: 'decimal',
        maximumFractionDigits: 20,
        minimumFractionDigits: 2
      }).format(clearValue)

      let parsed = parseInt(formattedValue)

      if (clearValue?.length === 1 && parsed === 0) {
        this.localValue = '0'
      } else if (clearValue?.length === 0) {
        this.localValue = null
      } else {
        this.localValue = formattedValue
      }
    },
    onInput (value) {
      const input = this.$refs.floatInput.$refs.input
      const startPos = input.selectionStart

      let clearValue = this.formatString(value)
      let parsedInt = parseInt(clearValue)

      if (clearValue?.length === 1 && parsedInt === 0) {
        this.$emit('input', '0')
      } else if (clearValue?.length === 0) {
        this.$emit('input', null)
      } else {
        this.$emit('input', clearValue)
      }

      this.$nextTick(() => {
        this.setCursorPosition(input, startPos)
      })
    },
    formatString (value) {
      return value?.replace(/,/g, '.').replace(/\s+/g, '')
    },
    setCursorPosition (el, pos) {
      el.focus()
      el.setSelectionRange(pos, pos)
    }
  }
}
